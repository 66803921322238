import Routes from "routes/index";
import { StyleProvider } from "@ant-design/cssinjs";

function App() {
    return (
        <div className="app">
            <StyleProvider hashPriority="high">
                <Routes />
            </StyleProvider>
        </div>
    );
}

export default App;
