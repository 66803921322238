import { getURLParam } from "utils";

const API_BASE_URL = process.env.REACT_APP_URL_API;

const DOWNLOAD_BOOKED_BY_NAILSOFT_URL = process.env.REACT_APP_DOWNLOAD_APP_URL;

const MASK_PHONE = `(999) 999-9999`;

const MASK_FILE_SIZE_UPLOAD = 2.5;

const ACCEPT_FILE_UPLOAD = "image/*";

const POLICY_URL = "https://nailsoft.com/policy/";

const GIFT_CARD_TERMS_URL = "https://nailsoft.com/gift-card-terms/";

const GOOGLE_PLAY_URL = process.env.REACT_APP_DOWNLOAD_CH_PLAY;

const APP_STORE_URL = process.env.REACT_APP_DOWNLOAD_APP_STORE;

const COOKIE_MAX_AGE = 604800; // 7 days

const COOKIE_MAX_AGE_CONSENT = 86400000; // 1000 days

const DRAWER_CART_WIDTH = 430;

const CODE_PHONE = "+1";

const RESEND_OTP_TIMER = 60;

const NAILSOFT_URL = `https://nailsoft.com/`;

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:$%^&*\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const GIFT_CARD_SITE_DOMAIN = process.env.REACT_APP_SITE_DOMAIN;

const MERCHANT_ID = getURLParam("merchant_id") || "0";

const TIME_BUILD = "e6c0b836-c859-48f5-ae6d-5b6d382cc33e";

const TEST_COOKIE_NAME = "490b3760-6115-4a0c-8b2f-61f81b0d2d20";

const HTTP_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOTFOUND: 404,
};

const COOKIE_NAMES = {
    BASKET: "basket",
    COOKIE_CONSENT: "cookieConsent",
    CUSTOMER: "customer",
    CUSTOMER_VERIFY: "customerVerify",
};

const GIFT_CARD_TEMPLATE_OPTIONS = [
    { id: 1, label: "Happy Anniversary", value: "Happy Anniversary", type: "system" },
    { id: 2, label: "Happy Birthday", value: "Happy Birthday", type: "system" },
    { id: 3, label: "Happy New Year", value: "Happy New Year", type: "system" },
    { id: 4, label: "Happy Valentine", value: "Happy Valentine", type: "system" },
    { id: 5, label: "Thank You", value: "Thank You", type: "system" },
    { id: 6, label: "I Love You", value: "I Love You", type: "system" },
    { id: 7, label: "Merry Christmas", value: "Merry Christmas", type: "system" },
    { id: 8, label: "Custom", value: "Custom", type: "custom" },
];

const SUGGEST_CURRENCY_BUTTONS = [
    {
        id: 10,
        value: 10,
        active: true,
    },
    {
        id: 20,
        value: 20,
        active: false,
    },
    {
        id: 50,
        value: 50,
        active: false,
    },
    {
        id: 100,
        value: 100,
        active: false,
    },
];

const GSM_CODE_POINTS = new Set([
    0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f, 0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037,
    0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d, 0x004e, 0x004f, 0x0050, 0x0051, 0x0052,
    0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063, 0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e,
    0x006f, 0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1, 0x00a3, 0x00a4, 0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
    0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec, 0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc, 0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8,
    0x03a9, 0x20ac,
]);

const GIFT_CARD_QR_CODE_TIMER = 300; // 5 minutes

const MAX_LENGTH_FIRST_NAME = 20;

const MAX_LENGTH_LAST_NAME = 20;

const DURATION_SHOW_MESSAGE = 2; //

const REGEX_REGISTER_USER_NAME = /^[A-Za-z0-9\s]+$/;

const MESSAGE_UPDATE_CART_ITEM_SUCCESS = `Your shopping cart has been updated!`;
const MESSAGE_ADD_ITEM_TO_CART_SUCCESS = `Added to Cart Successfully!`;
const MESSAGE_REMOVE_CART_ITEM_SUCCESS = `Item Successfully Removed!`;
const MESSAGE_UPLOAD_FILE_SUCCESS = "File uploaded successfully.";
const MESSAGE_UPLOAD_FILE_ERROR = "File upload failed!";

const APP_VERSION = process.env.REACT_APP_VERSION;

enum PAYMENT_GATE_WAYS {
    ARISE = "arise",
    NMI = "nmi",
}

const APP_ENV = process.env.REACT_APP_ENV;

const GIFT_CARD_MIN_AMOUNT = 5;
const GIFT_CARD_MAX_AMOUNT = 100;
const GIFT_CARD_DEFAULT_AMOUNT = 10;
const GIFT_CARD_UNIT_AMOUNT = 5;
const GIFT_CARD_MAX_LENGTH_MESSAGE = 25;

export {
    API_BASE_URL,
    MASK_PHONE,
    MASK_FILE_SIZE_UPLOAD,
    ACCEPT_FILE_UPLOAD,
    POLICY_URL,
    GIFT_CARD_TERMS_URL,
    COOKIE_MAX_AGE,
    DRAWER_CART_WIDTH,
    CODE_PHONE,
    RESEND_OTP_TIMER,
    REGEX_EMAIL,
    APP_STORE_URL,
    GOOGLE_PLAY_URL,
    HTTP_STATUS_CODES,
    COOKIE_MAX_AGE_CONSENT,
    GIFT_CARD_TEMPLATE_OPTIONS,
    SUGGEST_CURRENCY_BUTTONS,
    GSM_CODE_POINTS,
    COOKIE_NAMES,
    NAILSOFT_URL,
    DOWNLOAD_BOOKED_BY_NAILSOFT_URL,
    GIFT_CARD_QR_CODE_TIMER,
    MAX_LENGTH_FIRST_NAME,
    MAX_LENGTH_LAST_NAME,
    REGEX_REGISTER_USER_NAME,
    GIFT_CARD_SITE_DOMAIN,
    MERCHANT_ID,
    MESSAGE_REMOVE_CART_ITEM_SUCCESS,
    MESSAGE_ADD_ITEM_TO_CART_SUCCESS,
    MESSAGE_UPDATE_CART_ITEM_SUCCESS,
    DURATION_SHOW_MESSAGE,
    MESSAGE_UPLOAD_FILE_SUCCESS,
    MESSAGE_UPLOAD_FILE_ERROR,
    APP_VERSION,
    PAYMENT_GATE_WAYS,
    TIME_BUILD,
    TEST_COOKIE_NAME,
    APP_ENV,
    GIFT_CARD_MIN_AMOUNT,
    GIFT_CARD_MAX_AMOUNT,
    GIFT_CARD_DEFAULT_AMOUNT,
    GIFT_CARD_UNIT_AMOUNT,
    GIFT_CARD_MAX_LENGTH_MESSAGE,
};
