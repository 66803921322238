import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISlice, IPayloadSetCustomer } from "./type";
import { Cookies } from "react-cookie";
import { COOKIE_NAMES, MERCHANT_ID } from "configs";
import { formatCookieName } from "utils";
import IMerchant from "interfaces/IMerchant";

const cookies = new Cookies();

const initialState: ISlice = {
    token: "",
    openPhoneModal: false,
    openVerifyModal: false,
    openRegisterModal: false,
    phone: "",
    customer: cookies.get(formatCookieName(COOKIE_NAMES.CUSTOMER, MERCHANT_ID)) ? JSON.parse(JSON.stringify(cookies.get(formatCookieName(COOKIE_NAMES.CUSTOMER, MERCHANT_ID)))) : undefined,
    isVerify: cookies.get(formatCookieName(COOKIE_NAMES.CUSTOMER_VERIFY, MERCHANT_ID)) ? JSON.parse(JSON.stringify(cookies.get(formatCookieName(COOKIE_NAMES.CUSTOMER_VERIFY, MERCHANT_ID)))) : false,
    loading: true,
    theme: "",
};

const globalSlice = createSlice({
    name: "global",
    initialState: initialState,
    reducers: {
        togglePhoneModal: (state, action: PayloadAction<boolean>) => {
            state.openPhoneModal = action.payload;
        },
        toggleVerifyModal: (state, action: PayloadAction<boolean>) => {
            state.openVerifyModal = action.payload;
        },
        toggleRegisterModal: (state, action: PayloadAction<boolean>) => {
            state.openRegisterModal = action.payload;
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        getCustomerSuccess: (state, action: PayloadAction<IPayloadSetCustomer>) => {
            state.customer = action.payload.customer;
            state.isVerify = action.payload.isVerify;
        },
        getMerchantRequest: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            state.loading = true;
        },
        getMerchantSuccess: (state, action: PayloadAction<IMerchant>) => {
            state.loading = false;
            state.merchant = action.payload;
        },
        getMerchantError: (state) => {
            state.loading = false;
        },
        clearCustomer: (state) => {
            state.customer = undefined;
        },
        changeTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

const { reducer, actions } = globalSlice;

export const { toggleRegisterModal, togglePhoneModal, toggleVerifyModal, getCustomerSuccess, setPhone, getMerchantRequest, getMerchantSuccess, getMerchantError, clearCustomer, changeTheme } = actions;

export default reducer;
