import axios, { AxiosError } from "axios";
import { Cookies } from "react-cookie";
import { CODE_PHONE, TEST_COOKIE_NAME, COOKIE_MAX_AGE_CONSENT, GIFT_CARD_SITE_DOMAIN, GSM_CODE_POINTS, HTTP_STATUS_CODES } from "configs";
import CustomError from "providers/CustomError";
import Message from "components/Message";
import IBasketItem from "interfaces/IBasketItem";
import ICustomer from "interfaces/ICustomer";
import PaymentItem from "interfaces/IPaymentItem";
import numeral from "numeral";

const cookies = new Cookies();

const navigateToNotFoundPage = () => {
    const params = new URLSearchParams(window.location.search);
    const merchant_id = params.get("merchant_id");
    const token = params.get("token");
    if (!window.location.href.includes("not-found")) {
        window.location.href = `/not-found?merchant_id=${merchant_id}&token=${token}`;
    }
};

export function handleResponseError(error: AxiosError | CustomError) {
    if ((error instanceof CustomError && error.getStatusCode() === HTTP_STATUS_CODES.UNAUTHORIZED) || (axios.isAxiosError(error) && error.status === HTTP_STATUS_CODES.UNAUTHORIZED)) {
        navigateToNotFoundPage();
    } else {
        if (error.message) {
            Message.error({ text: error.message });
        } else if (typeof error === "string") {
            Message.error({ text: error });
        } else {
            Message.error({ text: "Unknow sever error!" });
        }
    }
}

export const getPhoneWithoutFormat = (phoneNumber: string) => {
    const phone = phoneNumber.replace(/\D/g, "");
    return `${phone}`;
};

export const formatPhone = (phoneNumber: string): string => {
    let phoneCleaned = phoneNumber.replace(/\D/g, "");
    if (!phoneCleaned || phoneCleaned?.length !== 10) return "";
    const formatPhone = phoneCleaned.replace(/(\d{3})(\d{0,3})(\d{0,4})/, "$1-$2-$3");
    return `${CODE_PHONE}${formatPhone}`;
};

export const maskPhone = (phoneNumber: string) => {
    if (!phoneNumber && typeof phoneNumber !== "string") return "";
    const phone = phoneNumber.replace(/\D/g, "");
    const matches = phone.replace(/(\d{3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    return `${matches}`;
};

export const maskPhoneWithCode = (phoneNumber: string) => {
    if (!phoneNumber && typeof phoneNumber !== "string") return "";
    const phone = phoneNumber.replace(/\D/g, "");
    const matches = phone.replace(/(\d{1})(\d{3})(\d{0,3})(\d{0,4})/, "+$1 ($2) $3-$4");
    return `${matches}`;
};

export const maskPhoneWithCodeV2 = (phoneNumber: string) => {
    if (!phoneNumber && typeof phoneNumber !== "string") return "";
    const phone = phoneNumber.replace(/\D/g, "");
    const matches = phone.replace(/(\d{1})(\d{3})(\d{0,3})(\d{0,4})/, "+$1 $2-$3-$4");
    return `${matches}`;
};

export const testValidPhone = (phoneNumber: string) => {
    const phoneCleaned = phoneNumber.replace(/[^\d]/g, "");
    if (phoneCleaned.length !== 10) return false;
    return true;
};

export const generateRandomId = () => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomId += charset.charAt(randomIndex);
    }

    return randomId;
};

export function formatMoney(currency: string): string {
    const money = currency.replace(/[^0-9.-]+/g, "");
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    return money ? formatter.format(Number(money)).replace("$", "$") : "";
}

export const ignoreNotGSMCharacter = (message: string) => {
    const data = [];
    for (const s of message) {
        const codePoint = s.codePointAt(0);
        if (codePoint && GSM_CODE_POINTS.has(codePoint)) {
            data.push(s);
        }
    }
    return data.join("");
};

export const toMMSS = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer - minutes * 60;

    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return `${minutesStr}:${secondsStr}`;
};

export const removeAscent = (str: string) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    return str;
};

export function getURLParam(name: string, url?: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(name);
}

export function formatCookieName(name: string, merchant_id: string) {
    return `${name}_${merchant_id}`;
}

export function getFileNameFromUrl(url: string): string | null {
    try {
        const urlObject = new URL(url);
        const parts = urlObject.href.split("?");
        return parts[0];
    } catch (error) {
        console.error("Error parsing URL:", error);
        return null;
    }
}

export const mapPaymentItems = (basketItems: IBasketItem[], customer: ICustomer) => {
    const data: PaymentItem[] = basketItems.map((item) => {
        return {
            giftCardTemplateName: item.giftcard.giftCardTemplateName,
            amount: item.amount,
            message: item.receiver?.message ?? "",
            forMySelf: item.sendOption === "MySelf" ? true : false,
            receiverUserId: item.receiver?.userId ? item.receiver?.userId : 0,
            phoneInvite: item.receiver?.phone ? formatPhone(getPhoneWithoutFormat(item.receiver.phone)) : "",
            emailInvite: item.receiver?.email ?? "",
            customerId: item.sendOption === "MySelf" ? customer.customerId : item.receiver?.customerId ? item.receiver?.customerId : 0,
            fileId: item.giftcard.fileId,
            attachSender: item.receiver?.sendAsSecret ? 0 : 1,
        };
    });
    return data;
};

export const getValue = (value: any) => {
    return value !== undefined ? value : null;
};

const setTestCookie = () => {
    cookies.set(TEST_COOKIE_NAME, "1", { maxAge: COOKIE_MAX_AGE_CONSENT, domain: GIFT_CARD_SITE_DOMAIN, sameSite: "none", secure: true });
};

const getTestCookie = () => {
    return !!cookies.get(TEST_COOKIE_NAME);
};

export const checkThirdPartyCookieEnabled = () => {
    setTestCookie();

    const testCookie = getTestCookie();

    cookies.remove(TEST_COOKIE_NAME, {
        domain: GIFT_CARD_SITE_DOMAIN,
        sameSite: "none",
        secure: true,
    });

    if (testCookie) {
        return true;
    } else {
        console.log("Third-party cookies are blocked or disabled.");
        return false;
    }
};


export const convertNumber = (value: any) => {
    return numeral(value).value() ?? 0;
};