import React from "react";
import LoadingGif from "assets/images/Loading.gif";
import { Spin as AntSpin, SpinProps } from "antd";
import "./index.css";

interface IProps extends SpinProps {}

const Spin: React.FC<IProps> = ({ spinning, children }) => {
    return (
        <AntSpin
            rootClassName="custom-spin"
            indicator={
                <div className="spinning-container">
                    <img src={LoadingGif} alt="Spinning" />
                </div>
            }
            spinning={spinning}
        >
            {children}
        </AntSpin>
    );
};

export default Spin;
