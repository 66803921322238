import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "components/Loading";

const MainLayout = lazy(() => import("layouts/Layout"));
const HomePage = lazy(() => import("pages/Home"));
const Checkout = lazy(() => import("pages/Checkout"));
const Basket = lazy(() => import("pages/Basket"));
const NotFound = lazy(() => import("pages/NotFound"));
const QRCode = lazy(() => import("pages/QRCode"));
const Invoice = lazy(() => import("pages/Invoice"));

const PRIVATE_ROUTES = [
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "/checkout",
        element: <Checkout />,
    },
    {
        path: "/basket",
        element: <Basket />,
    },
];

export default function routes() {
    return (
        <React.Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {PRIVATE_ROUTES.map((item) => {
                        return (
                            <Route
                                key={item.path}
                                path={item.path}
                                element={<React.Suspense fallback={<Loading />}>{item.element}</React.Suspense>}
                            />
                        );
                    })}
                </Route>
                <Route path="qr-code" element={<QRCode />} />
                <Route path="e-invoice" element={<Invoice />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </React.Suspense>
    );
}
