import { ThemeConfig } from "antd/es/config-provider";

const Theme: ThemeConfig = {
    components: {
        Checkbox: {
            colorPrimary: "#0078AA",
            colorPrimaryHover: "#0078AA",
            algorithm: true,
        },
    },
};

export default Theme;
