import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Theme from "theme";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { CookiesProvider } from "react-cookie";
import { GIFT_CARD_SITE_DOMAIN } from "configs";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <CookiesProvider
            defaultSetOptions={{ path: "/", domain: GIFT_CARD_SITE_DOMAIN, sameSite: "none", secure: true }}
        >
            <BrowserRouter>
                <ConfigProvider theme={Theme}>
                    <App />
                </ConfigProvider>
            </BrowserRouter>
        </CookiesProvider>
    </Provider>
);

reportWebVitals();
