import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISlice } from "./type";
import { Cookies } from "react-cookie";
import { COOKIE_NAMES, COOKIE_MAX_AGE, GIFT_CARD_SITE_DOMAIN, MERCHANT_ID } from "configs";
import { formatCookieName } from "utils";
import IBasketItem from "interfaces/IBasketItem";

const cookies = new Cookies();

const initialState: ISlice = {
    openModal: false,
    isEdit: false,
    isCheckoutSuccess: false,
    basket: cookies.get(formatCookieName(COOKIE_NAMES.BASKET, MERCHANT_ID)) ? JSON.parse(JSON.stringify(cookies.get(formatCookieName(COOKIE_NAMES.BASKET, MERCHANT_ID)))) : [],
};

const globalSlice = createSlice({
    name: "basket",
    initialState: initialState,
    reducers: {
        openBasketModal: (state, action: PayloadAction<IBasketItem>) => {
            state.openModal = true;
            state.basketItem = action.payload;
        },
        closeBasketModal: (state) => {
            state.openModal = false;
            state.basketItem = undefined;
            state.isEdit = false;
        },
        addItem: (state, action: PayloadAction<IBasketItem>) => {
            state.basket.push(action.payload);
            state.basketItem = undefined;
            state.openModal = false;
            if (cookies.get(COOKIE_NAMES.COOKIE_CONSENT) === "accepted") {
                cookies.set(formatCookieName(COOKIE_NAMES.BASKET, MERCHANT_ID), state.basket, { maxAge: COOKIE_MAX_AGE, domain: GIFT_CARD_SITE_DOMAIN, sameSite: "none", secure: true });
            }
        },
        removeItem: (state, action: PayloadAction<string>) => {
            const newBasket = state.basket.filter((x) => x.basketId !== action.payload);
            state.basket = newBasket;
            if (cookies.get(COOKIE_NAMES.COOKIE_CONSENT) === "accepted") {
                cookies.set(formatCookieName(COOKIE_NAMES.BASKET, MERCHANT_ID), state.basket, { maxAge: COOKIE_MAX_AGE, domain: GIFT_CARD_SITE_DOMAIN, sameSite: "none", secure: true });
            }
        },
        editItem: (state, action: PayloadAction<IBasketItem>) => {
            const newBasket: IBasketItem[] = JSON.parse(JSON.stringify(state.basket));
            const index = newBasket.findIndex((x) => x.basketId === action.payload.basketId);
            if (index !== -1) {
                newBasket[index] = action.payload;
                state.openModal = false;
                state.basketItem = undefined;
                state.isEdit = false;
                state.basket = newBasket;

                if (cookies.get(COOKIE_NAMES.COOKIE_CONSENT) === "accepted") {
                    cookies.set(formatCookieName(COOKIE_NAMES.BASKET, MERCHANT_ID), state.basket, { maxAge: COOKIE_MAX_AGE, domain: GIFT_CARD_SITE_DOMAIN, sameSite: "none", secure: true });
                }
            }
        },
        editBasket: (state, action: PayloadAction<string>) => {
            const newBasket: IBasketItem[] = JSON.parse(JSON.stringify(state.basket));
            const index = newBasket.findIndex((x) => x.basketId === action.payload);
            if (index !== -1) {
                state.openModal = true;
                state.basketItem = newBasket[index];
                state.isEdit = true;
            }
        },
        updateGiftCard: (state, action: PayloadAction<IBasketItem>) => {
            const newBasket: IBasketItem[] = JSON.parse(JSON.stringify(state.basket));
            const index = newBasket.findIndex((x) => x.basketId === action.payload.basketId);
            if (index !== -1) {
                newBasket[index] = action.payload;
                state.basketItem = action.payload;
                state.basket = newBasket;

                if (cookies.get(COOKIE_NAMES.COOKIE_CONSENT) === "accepted") {
                    cookies.set(formatCookieName(COOKIE_NAMES.BASKET, MERCHANT_ID), state.basket, { maxAge: COOKIE_MAX_AGE, domain: GIFT_CARD_SITE_DOMAIN, sameSite: "none", secure: true });
                }
            } else {
                state.basketItem = action.payload;
            }
        },
        updateTempItem: (state, action: PayloadAction<IBasketItem>) => {
            state.basketItem = action.payload;
        },
        checkout: (state, action: PayloadAction<boolean>) => {
            state.isCheckoutSuccess = action.payload;
            if (action.payload) {
                state.basketItem = undefined;
                state.basket = [];
            }
        },
    },
    extraReducers: (builder) => {},
});

const { reducer, actions } = globalSlice;

export const { openBasketModal, closeBasketModal, removeItem, addItem, editItem, editBasket, updateTempItem, updateGiftCard, checkout } = actions;

export default reducer;
