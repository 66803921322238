import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import global from "reducers/global";
import basket from "reducers/basket";
import giftcard from "reducers/giftcard";

export const store = configureStore({
    reducer: {
        global: global,
        basket: basket,
        giftcard: giftcard,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
