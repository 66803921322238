import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISlice } from "./type";

const initialState: ISlice = {
    selectedType: "Happy Anniversary",
};

const globalSlice = createSlice({
    name: "giftcard",
    initialState: initialState,
    reducers: {
        selectTemplateType: (state, action: PayloadAction<string>) => {
            state.selectedType = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

const { reducer, actions } = globalSlice;

export const { selectTemplateType } = actions;

export default reducer;
